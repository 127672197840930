<template>
    <v-app>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title pt-3 px-3 d-flex justify-content-between">
                <div class="breadcrumb-left">
                  <h4>
                    Manage pages
                  </h4>
                  <div class="breadcrumb-sub-header">
                    <router-link to="/dashboard">Dashboard </router-link>\ Pages
                  </div>
                </div>
                <div class="breadcrumb-right">
                  <v-btn  v-if="checkIsAccessible('page', 'create')"
                         @click="createPage()"
                         class="mt-4 btn btn-primary  mr-1"
                         dark>
                    <i class="fa fa-plus"></i>
                    Add page
                  </v-btn>

                </div>
              </div>
            </div>
              <div class="card-body">
                <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                  <div class="row">
                    <v-col cols="12" md="5">
                      <v-text-field label="Title"
                                    type="text"
                                    dense     v-on:keyup.enter="getPages()"
                                    outlined
                                    v-model="search.title"></v-text-field>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-select label="Status" outlined dense :items="active_statuses" item-text="title"   v-on:keyup.enter="getPages()"
                                item-value="value"
                                clearable
                                v-model="search.active"></v-select>
                    </v-col>

                    <v-col cols="12" md="4" class="text-right">
                      <v-btn
                          @click="getPages()"
                          class="btn btn-primary btn-search w-35"
                          :loading="loading"
                      >
                        <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                        Search
                      </v-btn>
                    </v-col>
                  </div>
                </div>
                <div class="">
                  <table class="table">
                    <thead>
                    <tr class="px-3">
                      <th class="px-3 wrap-column">Title</th>
                      <th class="px-3 text-center">Action</th>
                    </tr>
                    </thead>
                    <tbody class="text-left" >
                      <tr v-show="pages.length > 0" v-for="(page, index) of pages" :key="index" >
                      <td class="px-3 wrap-column">
                        <router-link :to="{ name: 'page-update' , params: {id: page.id}}"  class="navi-link" >{{page.title}}</router-link>&nbsp;&nbsp;
                        <i class="fas fa-circle" :class="page.is_active?'dot-active':'dot-inactive'"></i></td>
                      <td class="px-2 text-center">
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <slot>
                              <span>
                                <i class="flaticon-more-1"></i>
                              </span></slot>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover ">


                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('page', 'edit')">
                              <a href="#" class="navi-link" @click="editPage(page)">
                                                            <span class="navi-icon">
                                                              <i class="flaticon-edit"></i>
                                                            </span>
                                <span class="navi-text"> Edit </span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('page', 'delete')">
                              <a href="#" class="navi-link"
                                 @click="deletePage(page.id)">
                                                            <span class="navi-icon">
                                                              <i class="fas fa-trash"></i>
                                                            </span>
                                <span class="navi-text">  Delete</span>
                              </a>
                            </b-dropdown-text>

                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>

                      </td>
                    </tr>
                      <tr v-if="pages.length == 0">
                        <td colspan="2" class="text-center">
                          <strong>
                            No data available to display.
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <b-pagination
                      @input="getPages"   v-if="pages.length > 0"
                      v-model="page"  class="pull-right mt-7"
                      :total-rows="total"
                      :per-page="perPage"
                      first-number
                      last-number
                  ></b-pagination>
                </div>

            </div>
          </div>
        </div>
      </div>
    </v-app>
</template>
<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import PageService from "@/core/services/page/PageService";

    const pageService = new PageService();
    export default {
        components: {
        },
        data() {
            return {
                pages: [],
                active_statuses: [
                    {title: 'Active', value: "active"},
                    {title: 'Inactive', value: "in_active"}
                ],
                page_id: null,
                page: null,
                total: null,
                perPage: null, search: {
                    active: "active"
                },
              loading:false,
            };
        },
        mounted() {

            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Page", route: "wizard-1"},
                {title: "My Page"}
            ]);
          this.getPages();
        },
        methods: {
            getPages(type) {
              this.loading=true;
                pageService
                    .paginate(this.search)
                    .then(response => {
                        this.pages = response.data.data;
                        this.page = response.data.meta.current_page;
                        this.total = response.data.meta.total;
                        this.perPage = response.data.meta.per_page;
                        this.loading=false;
                    })
                    .catch(error => {
                        // console.log(error);
                      this.loading=false;
                    });
            },
            capitalize(string) {
                if (string) {
                    string = string.charAt(0).toUpperCase() + string.slice(1);
                    return string;
                }
            },
            openPage(page) {
                this.$refs["page"].showModal();
            },
            editPage(page) {
                this.$router.push({name: "page-update", params: {id: page.id}});
            },
            deletePage(id) {
                this.$confirm({
                    message: `Are you sure?`,
                    button: {
                        no: "No",
                        yes: "Yes"
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: confirm => {
                        if (confirm) {
                            pageService
                                .delete(id)
                                .then(response => {
                                    this.$snotify.success("Information deleted");

                                    this.getPages();
                                })
                                .catch(error => {
                                    //console.log(error);
                                });
                        }
                    }
                });
            },
             createPage(){
               this.$router.push({
                  name: 'page-new'
            })
          },
        }
    };
</script>
